<template>
  <v-card color="transparent" flat>
    <v-card-title>
      <v-spacer />
      <v-btn
        color="primary"
        icon
        @click="addItem"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table
        class="elevation-2"
        disable-pagination
        hide-default-footer
        :headers="headers"
        :items="items"
        :loading="processing"
    >
      <template v-slot:item.empresa="{ item }">
        <span
          v-if="!item.editedItem"
          class="ml-2"
        >
          {{ item.empresa ? item.empresa.description : '' }}
        </span>
        <my-select
          v-else
          v-model="item.editedItem.empresa"
          class="ml-2"
          clearable
          :disabled="item.processing"
          :error-messages="item.errors && item.errors['empresa.id'] ? item.errors['empresa.id'] : []"
          hide-details
          :loading="item.processing"
          placeholder="* Selecione a Filial"
          :resource="getCurrentUserCompanies()"
          return-object
          show-left-error-icon
          @input="delete item.errors['empresa.id']"
        />
      </template>
      <template v-slot:item.producao="{ item }">
        <span
            v-if="!item.editedItem"
            class="ml-2"
        >
          {{ item.producao ? item.producao.description : '' }}
        </span>
        <my-select
          v-else
          v-model="item.editedItem.producao"
          class="ml-2"
          clearable
          :disabled="item.processing"
          :error-messages="item.errors && item.errors['producao.id'] ? item.errors['producao.id'] : []"
          hide-details
          :loading="item.processing"
          placeholder="* Selecione a Origem"
          :resource="getCurrentUserFarms(item.editedItem.empresa)"
          return-object
          show-left-error-icon
          @input="delete item.errors['producao.id']"
        />
      </template>
      <template v-slot:item.operator_id="{ item }">
        <span
            v-if="!item.editedItem"
            class="ml-2"
        >
          {{ item.operator_id }}
        </span>
        <my-text-field
          v-else
          v-model="item.editedItem.operator_id"
          class="ml-2"
          :disabled="item.processing"
          :error-messages="item.errors && item.errors['operator_id'] ? item.errors['operator_id'] : []"
          hide-details
          :loading="item.processing"
          placeholder="* ID do Operador"
          show-left-error-icon
          @input="delete item.errors['operator_id']"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="!item.editedItem"
          class="ml-2 my-auto"
          icon
          small
          @click="editItem(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-else
          class="ml-2 my-auto"
          icon
          :loading="item.processing"
          small
          @click="saveItem(item)"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-dialog
            v-if="!item.editedItem"
            v-model="item.deleteDialog"
            persistent
            max-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ml-2 my-auto"
                icon
                small
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card
            :disabled="item.processing"
            :loading="item.processing"
          >
            <v-card-title class="headline"></v-card-title>
            <v-card-text class="title">Você tem certeza que quer excluir esse item?</v-card-text>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="cancelDeleteDialog(item)">Cancelar</v-btn>
              <v-btn color="primary" depressed @click="confirmDeleteItem(item)">Excluir</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
            v-else-if="item.editedItem && item.editedItem.id === 0"
            class="ml-2 my-auto"
            :disabled="item.processing"
            icon
            small
            @click="confirmDeleteItem(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
            v-else
            class="ml-2 my-auto"
            :disabled="item.processing"
            icon
            small
            @click="cancelEditItem(item)"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <custom-snackbar ref="snackbar" />
  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import MySelect from "@/view/components/MySelect.vue"
import MyTextField from "@/view/components/MyTextField.vue"
import store from "@/core/services/store"
import _ from "lodash"
import apiService from "@/core/services/api.service";
export default {
  components: {MyTextField, MySelect},
  data: () => ({
    processing: true,
    defaultItem: {
      id: 0,
      empresa: null,
      producao: null,
      operator_id: null,
      deleteDialog: false,
      errors: {},
      processing: false,
      editedItem: {
        id: 0,
        empresa: null,
        producao: null,
        operator_id: null,
      }
    },

    headers: [
      { text: 'Filial', value: 'empresa', width: '33%' },
      { text: 'Origem', value: 'producao', width: '33%' },
      { text: 'Operador', value: 'operator_id' },
      { text: '', value: 'actions', sortable: false, width: 75 },
    ],

    items: [],
  }),

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'RoutEasy', route: '/fretes/routeasy' },
      { title: 'Operadores' },
    ])

    this.init()
  },

  methods: {
    async init () {
      this.processing = true

      const config = {
        snackbar: this.$refs.snackbar
      }

      const response = (await apiService._get('fretes/routeasy/operadores', config)).data

      response.data.forEach((item) => {
        item.editedItem = null
        item.deleteDialog = false
        item.erros = {}
        item.processing = false
      })

      this.items = response.data

      this.processing = false
    },

    addItem () {
      this.items.push(JSON.parse(JSON.stringify(this.defaultItem)))
    },

    async saveItem (item) {
      const indexOfItem = this.getItemIndex(item)

      item.processing = true

      try {
        const config = {
          snackbar: this.$refs.snackbar
        }

        let response = null;

        if (item.id === 0) {
          response = (await apiService._post('fretes/routeasy/operadores', item.editedItem, config)).data
        } else {
          response = (await apiService._put(`fretes/routeasy/operadores/${item.id}`, item.editedItem, config)).data
        }

        response.data.editedItem = null
        response.data.deleteDialog = false
        response.data.erros = {}
        response.data.processing = false

        this.$set(this.items, indexOfItem, response.data)
      } catch (e) {
        item.processing = false

        if (e.response) {
          if (e.response.status === 422) {
            const newItem = Object.assign({}, JSON.parse(JSON.stringify(item)))

            newItem.errors = e.response.data.errors

            this.$set(this.items, indexOfItem, newItem)
          }
        }
      }
    },

    editItem (item) {
      const indexOfItem = this.getItemIndex(item)

      const newItem = Object.assign({}, item, {editedItem: JSON.parse(JSON.stringify(item))})

      this.$set(this.items, indexOfItem, newItem)
    },

    cancelEditItem (item) {
      const indexOfItem = this.getItemIndex(item)
      const oldItem = Object.assign({}, item)

      delete oldItem.editedItem

      this.$set(this.items, indexOfItem, oldItem)
    },

    async confirmDeleteItem (item) {
      const indexOfItem = this.getItemIndex(item)

      item.processing = true

      try {
        if (item.id !== 0) {
          await apiService._delete(`fretes/routeasy/operadores/${item.id}`, {
            snackbar: this.$refs.snackbar
          })
        }

        this.items.splice(indexOfItem, 1)
      } catch (e) {
        console.log(e)
        item.processing = false
      }
    },

    cancelDeleteDialog (item) {
      item.deleteDialog = false
    },

    getItemIndex (item) {
      return this.items.indexOf(item)
    },

    getCurrentUserCompanies () {
      const lista = []

      for (const item of store.getters.currentUser.empresas) {
        lista.push({
          id: item.id,
          description: item.nome,
        })
      }

      return lista
    },

    getCurrentUserFarms (empresa) {
      const lista = []

      const filteredFarms = _.filter(store.getters.currentUser.producoes, ['empresa_id', empresa ? empresa.id : 0]);

      for (const item of filteredFarms) {
        lista.push({
          id: item.id,
          description: item.sigla,
        })
      }

      return lista
    },

    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  }
}
</script>

<style scoped>
>>> .v-data-table {
  table {
    tr {
      td {
        background-color: white;
        padding: 0 !important;
      }
    }
  }
}
</style>