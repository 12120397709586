import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"transparent","flat":""}},[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","icon":""},on:{"click":_vm.addItem}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c(VDataTable,{staticClass:"elevation-2",attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.processing},scopedSlots:_vm._u([{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [(!item.editedItem)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.empresa ? item.empresa.description : '')+" ")]):_c('my-select',{staticClass:"ml-2",attrs:{"clearable":"","disabled":item.processing,"error-messages":item.errors && item.errors['empresa.id'] ? item.errors['empresa.id'] : [],"hide-details":"","loading":item.processing,"placeholder":"* Selecione a Filial","resource":_vm.getCurrentUserCompanies(),"return-object":"","show-left-error-icon":""},on:{"input":function($event){delete item.errors['empresa.id']}},model:{value:(item.editedItem.empresa),callback:function ($$v) {_vm.$set(item.editedItem, "empresa", $$v)},expression:"item.editedItem.empresa"}})]}},{key:"item.producao",fn:function(ref){
var item = ref.item;
return [(!item.editedItem)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.producao ? item.producao.description : '')+" ")]):_c('my-select',{staticClass:"ml-2",attrs:{"clearable":"","disabled":item.processing,"error-messages":item.errors && item.errors['producao.id'] ? item.errors['producao.id'] : [],"hide-details":"","loading":item.processing,"placeholder":"* Selecione a Origem","resource":_vm.getCurrentUserFarms(item.editedItem.empresa),"return-object":"","show-left-error-icon":""},on:{"input":function($event){delete item.errors['producao.id']}},model:{value:(item.editedItem.producao),callback:function ($$v) {_vm.$set(item.editedItem, "producao", $$v)},expression:"item.editedItem.producao"}})]}},{key:"item.operator_id",fn:function(ref){
var item = ref.item;
return [(!item.editedItem)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.operator_id)+" ")]):_c('my-text-field',{staticClass:"ml-2",attrs:{"disabled":item.processing,"error-messages":item.errors && item.errors['operator_id'] ? item.errors['operator_id'] : [],"hide-details":"","loading":item.processing,"placeholder":"* ID do Operador","show-left-error-icon":""},on:{"input":function($event){delete item.errors['operator_id']}},model:{value:(item.editedItem.operator_id),callback:function ($$v) {_vm.$set(item.editedItem, "operator_id", $$v)},expression:"item.editedItem.operator_id"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.editedItem)?_c(VBtn,{staticClass:"ml-2 my-auto",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1):_c(VBtn,{staticClass:"ml-2 my-auto",attrs:{"icon":"","loading":item.processing,"small":""},on:{"click":function($event){return _vm.saveItem(item)}}},[_c(VIcon,[_vm._v("mdi-check")])],1),(!item.editedItem)?_c(VDialog,{attrs:{"persistent":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 my-auto",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c(VCard,{attrs:{"disabled":item.processing,"loading":item.processing}},[_c(VCardTitle,{staticClass:"headline"}),_c(VCardText,{staticClass:"title"},[_vm._v("Você tem certeza que quer excluir esse item?")]),_c(VCardActions,{staticClass:"mx-2"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.cancelDeleteDialog(item)}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.confirmDeleteItem(item)}}},[_vm._v("Excluir")])],1)],1)],1):(item.editedItem && item.editedItem.id === 0)?_c(VBtn,{staticClass:"ml-2 my-auto",attrs:{"disabled":item.processing,"icon":"","small":""},on:{"click":function($event){return _vm.confirmDeleteItem(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_c(VBtn,{staticClass:"ml-2 my-auto",attrs:{"disabled":item.processing,"icon":"","small":""},on:{"click":function($event){return _vm.cancelEditItem(item)}}},[_c(VIcon,[_vm._v("mdi-cancel")])],1)]}}])}),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }