<template>
  <div>
    <v-text-field
        v-model="internalValueFormatted"
        v-mask="mask"
        :autofocus="autofocus"
        :clearable="clearable"
        dense
        :disabled="disabled"
        :error-messages="hideErrors ? [] : errorMessages"
        :label="label + (required ? ' *' : '')"
        :hide-details="hideDetails"
        :outlined="outlined"
        persistent-placeholder
        :placeholder="placeholder"
        :prefix="prefix"
        :readonly="readonly"
        ref="component"
        :append-icon="type==='date' ? 'mdi-calendar' : ''"
        :suffix="suffix"
        @blur="internalValue = formatValueFromInput(internalValueFormatted)"
    >
      <template v-slot:append-outer>
        <v-tooltip v-if="showLeftErrorIcon && typeof errorMessages === 'string'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="error"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
            >
              mdi-alert-circle
            </v-icon>
          </template>
          <span>{{ errorMessages }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <input :value="internalValue" type="hidden" />
  </div>
</template>

<script>
import { VTextField } from "vuetify/lib/components";
import {VueMaskDirective} from "v-mask";
import MySelect from "@/view/components/MySelect.vue";
import moment from 'moment'

export default {
  name: 'MyTextField',
  components: {MySelect},

  directives: { VueMaskDirective },

  extends: VTextField,

  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto'
    },
    label: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showLeftErrorIcon: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: null
    },
  },

  data () {
    return {
      hideErrors: false,
      internalValue: this.value,
      internalValueFormatted: this.formatValueFromDatabase(this.value),
    }
  },

  watch: {
    value (newValue) {
      this.internalValue = newValue
      this.internalValueFormatted = this.formatValueFromDatabase(newValue)
    },

    internalValue (val) {
      this.errorMessages = []
      this.hideErrors = true
      this.$emit('input', val)
    },

    internalValueFormatted (val) {
      this.errorMessages = []
      this.hideErrors = true
      if (this.type !== 'date') {
        this.$emit('input', val)
      }
    },

    errorMessages () {
      this.hideErrors = false
    }
  },

  methods: {
    formatValueFromInput (val) {
      if (!val) return null

      try {
        if (this.type === 'date') {
          return moment(val, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }
      } catch (e) {
        console.error(e)
      }

      return val
    },

    formatValueFromDatabase (val) {
      if (!val) return null

      try {
        if (this.type === 'date') {
          return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        }
      } catch (e) {
        console.error(e)
      }

      return val
    },

    focus () {
      this.$refs.component.$refs.input.focus()
    },

    select () {
      this.$refs.component.$el.querySelector('input').select()
    },

  },
}
</script>