import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"clearable":_vm.clearable,"dense":"","disabled":_vm.disabled,"error-messages":_vm.hideErrors ? [] : _vm.errorMessages,"label":_vm.label + (_vm.required ? ' *' : ''),"loading":_vm.loading,"hide-details":_vm.hideDetails,"item-value":"id","item-text":"description","items":_vm.computedItems,"outlined":_vm.outlined,"persistent-placeholder":"","placeholder":_vm.placeholder,"prefix":_vm.prefix,"readonly":_vm.readonly,"return-object":_vm.returnObject},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.showLeftErrorIcon && typeof _vm.errorMessages === 'string')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"error","disabled":_vm.disabled}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,4050976182)},[_c('span',[_vm._v(_vm._s(_vm.errorMessages))])]):_vm._e()]},proxy:true}]),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }