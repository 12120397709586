<template>
  <v-select
    v-model="internalValue"
    :clearable="clearable"
    dense
    :disabled="disabled"
    :error-messages="hideErrors ? [] : errorMessages"
    :label="label + (required ? ' *' : '')"
    :loading="loading"
    :hide-details="hideDetails"
    item-value="id"
    item-text="description"
    :items="computedItems"
    :outlined="outlined"
    persistent-placeholder
    :placeholder="placeholder"
    :prefix="prefix"
    :readonly="readonly"
    :return-object="returnObject"
  >
    <template v-slot:append-outer>
      <v-tooltip v-if="showLeftErrorIcon && typeof errorMessages === 'string'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="error"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
          >
            mdi-alert-circle
          </v-icon>
        </template>
        <span>{{ errorMessages }}</span>
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
import {VSelect} from "vuetify/lib/components";
import ApiService from "@/core/services/api.service";

export default {
  name: 'MySelect',

  extends: VSelect,

  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Array,
      default: () => []
    },
    filerColumn: {
      type: String,
      default: null
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto'
    },
    label: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    resource: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    showLeftErrorIcon: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: null
    },
  },

  data () {
    return {
      hideErrors: false,
      internalValue: this.value,
      items: [],
      loading: false
    }
  },

  computed: {
    computedItems () {
      const filterColumn = this.filterColumn ?? 'id'

      if (this.filter.length > 0) {
        const filter = this.filter;
        return this.items.filter((item) => filter.includes(item[filterColumn]))
      } else {
        return this.items
      }
    }
  },

  watch: {
    value (newValue) {
      this.internalValue = newValue
    },

    internalValue (val) {
      this.errorMessages = []
      this.hideErrors = true
      this.$emit('input', val)
    },

    errorMessages () {
      this.hideErrors = false
    },

    resource () {
      this.init()
    }
  },

  methods: {
    async init () {
      this.loading = true
      this.disable = true

      if (this.resource && typeof this.resource === 'string') {
        this.items = (await ApiService.get(this.resource)).data
      } else if (typeof this.resource === 'object') {
        this.items = this.resource
      } else {
        this.items = []
      }

      this.disable = false
      this.loading = false
    }
  },

  mounted() {
    this.init()
  }
}
</script>